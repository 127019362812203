import * as React from 'react';
import s from './WishlistProductItem.scss';
import {RemoveIcon} from '../Icons/RemoveIcon';

import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {IProductItemProps, ProductItem} from '../../../common/components/ProductItem/ProductItem';
import {withGlobals} from '../../../globalPropsContext';
import {IProvidedGlobalProps} from '../../types/app-types';

type WishlistProductItemProps = IProductItemProps & IProvidedGlobalProps;

const Remove: React.FunctionComponent<{handleClick: any}> = ({handleClick}) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <a data-hook="remove-product" onClick={handleClick}>
      <RemoveIcon className={s.removeIcon} />
    </a>
  );
};

const WishlistProductItemComponent = (props: WishlistProductItemProps & IProvidedTranslationProps) => {
  const {
    t,
    product,
    globals,
    globals: {removeProduct},
  } = props;

  return (
    <div className={s.container}>
      <Remove handleClick={() => removeProduct(product.id)} />
      <ProductItem {...props} {...globals} t={t} />
    </div>
  );
};

export const WishlistProductItem = withGlobals(withTranslations()(WishlistProductItemComponent));
