import React, {FunctionComponent} from 'react';
import s from './LoadMore.scss';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {IWishlistProps} from '../WishlistApp';
import {withGlobals} from '../../../../globalPropsContext';

export enum LoadMoreDataHooks {
  Button = 'load-more-button',
}

const LoadMoreComponent: FunctionComponent<IProvidedTranslationProps & IWishlistProps> = (props) => {
  const {
    t,
    globals: {handleLoadMore},
  } = props;

  return (
    <button type="button" data-hook={LoadMoreDataHooks.Button} className={s.loadMore} onClick={() => handleLoadMore()}>
      {t('loadMoreButton')}
    </button>
  );
};

export const LoadMore = withGlobals(withTranslations()(LoadMoreComponent));
