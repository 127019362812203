import * as React from 'react';

interface RemoveIconProps {
  className?: string;
  width?: number;
  height?: number;
}

export const RemoveIcon = React.memo((props: RemoveIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 22 22" {...props}>
      <defs>
        <circle id="b" cx="10" cy="10" r="10"></circle>
        <filter id="a" width="115%" height="115%" x="-7.5%" y="-7.5%" filterUnits="objectBoundingBox">
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="0.5"></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.198171422 0"></feColorMatrix>
        </filter>
        <path
          id="c"
          d="M4 3.385L7.385 0 8 .615 4.615 4 8 7.385 7.385 8 4 4.615.615 8 0 7.385 3.385 4 0 .615.615 0 4 3.385z"></path>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)">
          <use fill="#000" filter="url(#a)" xlinkHref="#b"></use>
          <use fill="#FFF" xlinkHref="#b"></use>
        </g>
        <g transform="translate(7 7)">
          <mask id="d" fill="#fff">
            <use xlinkHref="#c"></use>
          </mask>
          <path fill="#000" d="M-8-8h24v24H-8z" mask="url(#d)"></path>
        </g>
      </g>
    </svg>
  );
});
