/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface AcceptProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
export const Accept: React.FC<AcceptProps> = ({size, ...props}) => (
  <svg viewBox="0 0 16 13" fill="currentColor" width={ size || "16" } height={ size || "13" } {...props}>
    <defs>
      <path fill="currentColor" d="M 14.4 2.05 L 5.85 11.05 1.35 6.1 0.65 6.75 5.85 12.45 15.05 2.7 14.4 2.05 Z" id="viIcon" />
    </defs>
    <use xlinkHref="#viIcon" />
  </svg>
);
Accept.displayName = 'Accept';
/* tslint:enable */
/* eslint-enable */
