import * as React from 'react';
import {
  Pagination as PaginationUI,
  PaginationProps,
} from '../../../../gallery/components/ProductList/Pagination/Pagination';
import {withGlobals} from '../../../../globalPropsContext';
import {IProvidedGlobalProps} from '../../../types/app-types';
import {useStyles} from '@wix/tpa-settings/react';
import {useStylesParams} from '../../../../stylesParamsContext';

const PaginationComponent: React.FunctionComponent<IProvidedGlobalProps> = (props) => {
  const {currentPage, handlePagination, totalProducts, paginationMode, productsPerPage} = props.globals;

  const styles = useStyles();
  const stylesParams = useStylesParams();
  const showFirstLastNavButtons = styles.get(stylesParams.gallery_paginationFirstLastArrows);
  const paginationProps: PaginationProps = {
    currentPage,
    paginationMode,
    totalPages: Math.ceil(totalProducts / productsPerPage),
    showFirstLastNavButtons,
    handlePagination,
  };

  return (
    <div data-hook="wishlist-pagination">
      <PaginationUI {...paginationProps} />
    </div>
  );
};

export const Pagination = withGlobals(PaginationComponent);
